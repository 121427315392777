<bc-catch-messages [manager]="bcCountriesMessagesManager" (finished)="setupCountries()">
  <bc-message key="AF" i18n="Afghanistan@@AF">Afghanistan</bc-message>
  <bc-message key="AL" i18n="Albania@@AL">Albania</bc-message>
  <bc-message key="DZ" i18n="Algeria@@DZ">Algeria</bc-message>
  <bc-message key="AS" i18n="American Samoa@@AS">American Samoa</bc-message>
  <bc-message key="AD" i18n="Andorra@@AD">Andorra</bc-message>
  <bc-message key="AO" i18n="Angola@@AO">Angola</bc-message>
  <bc-message key="AI" i18n="Anguilla@@AI">Anguilla</bc-message>
  <bc-message key="AQ" i18n="Antarctica@@AQ">Antarctica</bc-message>
  <bc-message key="AG" i18n="Antigua and Barbuda@@AG">Antigua and Barbuda</bc-message>
  <bc-message key="AR" i18n="Argentina@@AR">Argentina</bc-message>
  <bc-message key="AM" i18n="Armenia@@AM">Armenia</bc-message>
  <bc-message key="AW" i18n="Aruba@@AW">Aruba</bc-message>
  <bc-message key="AU" i18n="Australia@@AU">Australia</bc-message>
  <bc-message key="AT" i18n="Austria@@AT">Austria</bc-message>
  <bc-message key="AZ" i18n="Azerbaijan@@AZ">Azerbaijan</bc-message>
  <bc-message key="BS" i18n="Bahamas@@BS">Bahamas</bc-message>
  <bc-message key="BH" i18n="Bahrain@@BH">Bahrain</bc-message>
  <bc-message key="BD" i18n="Bangladesh@@BD">Bangladesh</bc-message>
  <bc-message key="BB" i18n="Barbados@@BB">Barbados</bc-message>
  <bc-message key="BY" i18n="Belarus@@BY">Belarus</bc-message>
  <bc-message key="BE" i18n="Belgium@@BE">Belgium</bc-message>
  <bc-message key="BZ" i18n="Belize@@BZ">Belize</bc-message>
  <bc-message key="BJ" i18n="Benin@@BJ">Benin</bc-message>
  <bc-message key="BM" i18n="Bermuda@@BM">Bermuda</bc-message>
  <bc-message key="BT" i18n="Bhutan@@BT">Bhutan</bc-message>
  <bc-message key="BO" i18n="Bolivia@@BO">Bolivia</bc-message>
  <bc-message key="BA" i18n="Bosnia and Herzegovina@@BA">Bosnia and Herzegovina</bc-message>
  <bc-message key="BW" i18n="Botswana@@BW">Botswana</bc-message>
  <bc-message key="BV" i18n="Bouvet Island@@BV">Bouvet Island</bc-message>
  <bc-message key="BR" i18n="Brazil@@BR">Brazil</bc-message>
  <bc-message key="IO" i18n="British Indian Ocean Territory@@IO">British Indian Ocean Territory</bc-message>
  <bc-message key="BN" i18n="Brunei Darussalam@@BN">Brunei Darussalam</bc-message>
  <bc-message key="BG" i18n="Bulgaria@@BG">Bulgaria</bc-message>
  <bc-message key="BF" i18n="Burkina Faso@@BF">Burkina Faso</bc-message>
  <bc-message key="BI" i18n="Burundi@@BI">Burundi</bc-message>
  <bc-message key="KH" i18n="Cambodia@@KH">Cambodia</bc-message>
  <bc-message key="CM" i18n="Cameroon@@CM">Cameroon</bc-message>
  <bc-message key="CA" i18n="Canada@@CA">Canada</bc-message>
  <bc-message key="CV" i18n="Cape Verde@@CV">Cape Verde</bc-message>
  <bc-message key="KY" i18n="Cayman Islands@@KY">Cayman Islands</bc-message>
  <bc-message key="CF" i18n="Central African Republic@@CF">Central African Republic</bc-message>
  <bc-message key="TD" i18n="Chad@@TD">Chad</bc-message>
  <bc-message key="CL" i18n="Chile@@CL">Chile</bc-message>
  <bc-message key="CN" i18n="China@@CN">China</bc-message>
  <bc-message key="CX" i18n="Christmas Island@@CX">Christmas Island</bc-message>
  <bc-message key="CC" i18n="Cocos (Keeling) Islands@@CC">Cocos (Keeling) Islands</bc-message>
  <bc-message key="CO" i18n="Colombia@@CO">Colombia</bc-message>
  <bc-message key="KM" i18n="Comoros@@KM">Comoros</bc-message>
  <bc-message key="CG" i18n="Congo@@CG">Congo</bc-message>
  <bc-message key="CD" i18n="Congo, the Democratic Republic of the@@CD">Congo, the Democratic Republic of the</bc-message>
  <bc-message key="CK" i18n="Cook Islands@@CK">Cook Islands</bc-message>
  <bc-message key="CR" i18n="Costa Rica@@CR">Costa Rica</bc-message>
  <bc-message key="CI" i18n="Cote D'Ivoire@@CI">Cote D'Ivoire</bc-message>
  <bc-message key="HR" i18n="Croatia@@HR">Croatia</bc-message>
  <bc-message key="CU" i18n="Cuba@@CU">Cuba</bc-message>
  <bc-message key="CY" i18n="Cyprus@@CY">Cyprus</bc-message>
  <bc-message key="CZ" i18n="Czech Republic@@CZ">Czech Republic</bc-message>
  <bc-message key="DK" i18n="Denmark@@DK">Denmark</bc-message>
  <bc-message key="DJ" i18n="Djibouti@@DJ">Djibouti</bc-message>
  <bc-message key="DM" i18n="Dominica@@DM">Dominica</bc-message>
  <bc-message key="DO" i18n="Dominican Republic@@DO">Dominican Republic</bc-message>
  <bc-message key="EC" i18n="Ecuador@@EC">Ecuador</bc-message>
  <bc-message key="EG" i18n="Egypt@@EG">Egypt</bc-message>
  <bc-message key="SV" i18n="El Salvador@@SV">El Salvador</bc-message>
  <bc-message key="GQ" i18n="Equatorial Guinea@@GQ">Equatorial Guinea</bc-message>
  <bc-message key="ER" i18n="Eritrea@@ER">Eritrea</bc-message>
  <bc-message key="EE" i18n="Estonia@@EE">Estonia</bc-message>
  <bc-message key="ET" i18n="Ethiopia@@ET">Ethiopia</bc-message>
  <bc-message key="FK" i18n="Falkland Islands (Malvinas)@@FK">Falkland Islands (Malvinas)</bc-message>
  <bc-message key="FO" i18n="Faroe Islands@@FO">Faroe Islands</bc-message>
  <bc-message key="FJ" i18n="Fiji@@FJ">Fiji</bc-message>
  <bc-message key="FI" i18n="Finland@@FI">Finland</bc-message>
  <bc-message key="FR" i18n="France@@FR">France</bc-message>
  <bc-message key="GF" i18n="French Guiana@@GF">French Guiana</bc-message>
  <bc-message key="PF" i18n="French Polynesia@@PF">French Polynesia</bc-message>
  <bc-message key="TF" i18n="French Southern Territories@@TF">French Southern Territories</bc-message>
  <bc-message key="GA" i18n="Gabon@@GA">Gabon</bc-message>
  <bc-message key="GM" i18n="Gambia@@GM">Gambia</bc-message>
  <bc-message key="GE" i18n="Georgia@@GE">Georgia</bc-message>
  <bc-message key="DE" i18n="Germany@@DE">Germany</bc-message>
  <bc-message key="GH" i18n="Ghana@@GH">Ghana</bc-message>
  <bc-message key="GI" i18n="Gibraltar@@GI">Gibraltar</bc-message>
  <bc-message key="GR" i18n="Greece@@GR">Greece</bc-message>
  <bc-message key="GL" i18n="Greenland@@GL">Greenland</bc-message>
  <bc-message key="GD" i18n="Grenada@@GD">Grenada</bc-message>
  <bc-message key="GP" i18n="Guadeloupe@@GP">Guadeloupe</bc-message>
  <bc-message key="GU" i18n="Guam@@GU">Guam</bc-message>
  <bc-message key="GT" i18n="Guatemala@@GT">Guatemala</bc-message>
  <bc-message key="GN" i18n="Guinea@@GN">Guinea</bc-message>
  <bc-message key="GW" i18n="Guinea-Bissau@@GW">Guinea-Bissau</bc-message>
  <bc-message key="GY" i18n="Guyana@@GY">Guyana</bc-message>
  <bc-message key="HT" i18n="Haiti@@HT">Haiti</bc-message>
  <bc-message key="HM" i18n="Heard Island and Mcdonald Islands@@HM">Heard Island and Mcdonald Islands</bc-message>
  <bc-message key="VA" i18n="Holy See (Vatican City State)@@VA">Holy See (Vatican City State)</bc-message>
  <bc-message key="HN" i18n="Honduras@@HN">Honduras</bc-message>
  <bc-message key="HK" i18n="Hong Kong@@HK">Hong Kong</bc-message>
  <bc-message key="HU" i18n="Hungary@@HU">Hungary</bc-message>
  <bc-message key="IS" i18n="Iceland@@IS">Iceland</bc-message>
  <bc-message key="IN" i18n="India@@IN">India</bc-message>
  <bc-message key="ID" i18n="Indonesia@@ID">Indonesia</bc-message>
  <bc-message key="IR" i18n="Iran, Islamic Republic of@@IR">Iran, Islamic Republic of</bc-message>
  <bc-message key="IQ" i18n="Iraq@@IQ">Iraq</bc-message>
  <bc-message key="IE" i18n="Ireland@@IE">Ireland</bc-message>
  <bc-message key="IL" i18n="Israel@@IL">Israel</bc-message>
  <bc-message key="IT" i18n="Italy@@IT">Italy</bc-message>
  <bc-message key="JM" i18n="Jamaica@@JM">Jamaica</bc-message>
  <bc-message key="JP" i18n="Japan@@JP">Japan</bc-message>
  <bc-message key="JO" i18n="Jordan@@JO">Jordan</bc-message>
  <bc-message key="KZ" i18n="Kazakhstan@@KZ">Kazakhstan</bc-message>
  <bc-message key="KE" i18n="Kenya@@KE">Kenya</bc-message>
  <bc-message key="KI" i18n="Kiribati@@KI">Kiribati</bc-message>
  <bc-message key="XK" i18n="Kosovo@@XK">Kosovo</bc-message>
  <bc-message key="KP" i18n="Korea, Democratic People's Republic of@@KP">Korea, Democratic People's Republic of</bc-message>
  <bc-message key="KR" i18n="Korea, Republic of@@KR">Korea, Republic of</bc-message>
  <bc-message key="KW" i18n="Kuwait@@KW">Kuwait</bc-message>
  <bc-message key="KG" i18n="Kyrgyzstan@@KG">Kyrgyzstan</bc-message>
  <bc-message key="LA" i18n="Lao People's Democratic Republic@@LA">Lao People's Democratic Republic</bc-message>
  <bc-message key="LV" i18n="Latvia@@LV">Latvia</bc-message>
  <bc-message key="LB" i18n="Lebanon@@LB">Lebanon</bc-message>
  <bc-message key="LS" i18n="Lesotho@@LS">Lesotho</bc-message>
  <bc-message key="LR" i18n="Liberia@@LR">Liberia</bc-message>
  <bc-message key="LY" i18n="Libyan Arab Jamahiriya@@LY">Libyan Arab Jamahiriya</bc-message>
  <bc-message key="LI" i18n="Liechtenstein@@LI">Liechtenstein</bc-message>
  <bc-message key="LT" i18n="Lithuania@@LT">Lithuania</bc-message>
  <bc-message key="LU" i18n="Luxembourg@@LU">Luxembourg</bc-message>
  <bc-message key="MO" i18n="Macao@@MO">Macao</bc-message>
  <bc-message key="MK" i18n="Macedonia, the Former Yugoslav Republic of@@MK">Macedonia, the Former Yugoslav Republic of</bc-message>
  <bc-message key="MG" i18n="Madagascar@@MG">Madagascar</bc-message>
  <bc-message key="MW" i18n="Malawi@@MW">Malawi</bc-message>
  <bc-message key="MY" i18n="Malaysia@@MY">Malaysia</bc-message>
  <bc-message key="MV" i18n="Maldives@@MV">Maldives</bc-message>
  <bc-message key="ML" i18n="Mali@@ML">Mali</bc-message>
  <bc-message key="MT" i18n="Malta@@MT">Malta</bc-message>
  <bc-message key="MH" i18n="Marshall Islands@@MH">Marshall Islands</bc-message>
  <bc-message key="MQ" i18n="Martinique@@MQ">Martinique</bc-message>
  <bc-message key="MR" i18n="Mauritania@@MR">Mauritania</bc-message>
  <bc-message key="MU" i18n="Mauritius@@MU">Mauritius</bc-message>
  <bc-message key="YT" i18n="Mayotte@@YT">Mayotte</bc-message>
  <bc-message key="MX" i18n="Mexico@@MX">Mexico</bc-message>
  <bc-message key="FM" i18n="Micronesia, Federated States of@@FM">Micronesia, Federated States of</bc-message>
  <bc-message key="MD" i18n="Moldova, Republic of@@MD">Moldova, Republic of</bc-message>
  <bc-message key="MC" i18n="Monaco@@MC">Monaco</bc-message>
  <bc-message key="MN" i18n="Mongolia@@MN">Mongolia</bc-message>
  <bc-message key="MS" i18n="Montserrat@@MS">Montserrat</bc-message>
  <bc-message key="MA" i18n="Morocco@@MA">Morocco</bc-message>
  <bc-message key="MZ" i18n="Mozambique@@MZ">Mozambique</bc-message>
  <bc-message key="MM" i18n="Myanmar@@MM">Myanmar</bc-message>
  <bc-message key="NA" i18n="Namibia@@NA">Namibia</bc-message>
  <bc-message key="NR" i18n="Nauru@@NR">Nauru</bc-message>
  <bc-message key="NP" i18n="Nepal@@NP">Nepal</bc-message>
  <bc-message key="NL" i18n="Netherlands@@NL">Netherlands</bc-message>
  <bc-message key="NC" i18n="New Caledonia@@NC">New Caledonia</bc-message>
  <bc-message key="NZ" i18n="New Zealand@@NZ">New Zealand</bc-message>
  <bc-message key="NI" i18n="Nicaragua@@NI">Nicaragua</bc-message>
  <bc-message key="NE" i18n="Niger@@NE">Niger</bc-message>
  <bc-message key="NG" i18n="Nigeria@@NG">Nigeria</bc-message>
  <bc-message key="NU" i18n="Niue@@NU">Niue</bc-message>
  <bc-message key="NF" i18n="Norfolk Island@@NF">Norfolk Island</bc-message>
  <bc-message key="MP" i18n="Northern Mariana Islands@@MP">Northern Mariana Islands</bc-message>
  <bc-message key="NO" i18n="Norway@@NO">Norway</bc-message>
  <bc-message key="OM" i18n="Oman@@OM">Oman</bc-message>
  <bc-message key="PK" i18n="Pakistan@@PK">Pakistan</bc-message>
  <bc-message key="PW" i18n="Palau@@PW">Palau</bc-message>
  <bc-message key="PS" i18n="Palestinian Territory, Occupied@@PS">Palestinian Territory, Occupied</bc-message>
  <bc-message key="PA" i18n="Panama@@PA">Panama</bc-message>
  <bc-message key="PG" i18n="Papua New Guinea@@PG">Papua New Guinea</bc-message>
  <bc-message key="PY" i18n="Paraguay@@PY">Paraguay</bc-message>
  <bc-message key="PE" i18n="Peru@@PE">Peru</bc-message>
  <bc-message key="PH" i18n="Philippines@@PH">Philippines</bc-message>
  <bc-message key="PN" i18n="Pitcairn@@PN">Pitcairn</bc-message>
  <bc-message key="PL" i18n="Poland@@PL">Poland</bc-message>
  <bc-message key="PT" i18n="Portugal@@PT">Portugal</bc-message>
  <bc-message key="PR" i18n="Puerto Rico@@PR">Puerto Rico</bc-message>
  <bc-message key="QA" i18n="Qatar@@QA">Qatar</bc-message>
  <bc-message key="RE" i18n="Reunion@@RE">Reunion</bc-message>
  <bc-message key="RO" i18n="Romania@@RO">Romania</bc-message>
  <bc-message key="RU" i18n="Russian Federation@@RU">Russian Federation</bc-message>
  <bc-message key="RW" i18n="Rwanda@@RW">Rwanda</bc-message>
  <bc-message key="SH" i18n="Saint Helena@@SH">Saint Helena</bc-message>
  <bc-message key="KN" i18n="Saint Kitts and Nevis@@KN">Saint Kitts and Nevis</bc-message>
  <bc-message key="LC" i18n="Saint Lucia@@LC">Saint Lucia</bc-message>
  <bc-message key="PM" i18n="Saint Pierre and Miquelon@@PM">Saint Pierre and Miquelon</bc-message>
  <bc-message key="VC" i18n="Saint Vincent and the Grenadines@@VC">Saint Vincent and the Grenadines</bc-message>
  <bc-message key="WS" i18n="Samoa@@WS">Samoa</bc-message>
  <bc-message key="SM" i18n="San Marino@@SM">San Marino</bc-message>
  <bc-message key="ST" i18n="Sao Tome and Principe@@ST">Sao Tome and Principe</bc-message>
  <bc-message key="SA" i18n="Saudi Arabia@@SA">Saudi Arabia</bc-message>
  <bc-message key="SN" i18n="Senegal@@SN">Senegal</bc-message>
  <bc-message key="SC" i18n="Seychelles@@SC">Seychelles</bc-message>
  <bc-message key="SL" i18n="Sierra Leone@@SL">Sierra Leone</bc-message>
  <bc-message key="SG" i18n="Singapore@@SG">Singapore</bc-message>
  <bc-message key="SK" i18n="Slovakia@@SK">Slovakia</bc-message>
  <bc-message key="SI" i18n="Slovenia@@SI">Slovenia</bc-message>
  <bc-message key="SB" i18n="Solomon Islands@@SB">Solomon Islands</bc-message>
  <bc-message key="SO" i18n="Somalia@@SO">Somalia</bc-message>
  <bc-message key="ZA" i18n="South Africa@@ZA">South Africa</bc-message>
  <bc-message key="GS" i18n="South Georgia and the South Sandwich Islands@@GS">South Georgia and the South Sandwich Islands</bc-message>
  <bc-message key="ES" i18n="Spain@@ES">Spain</bc-message>
  <bc-message key="LK" i18n="Sri Lanka@@LK">Sri Lanka</bc-message>
  <bc-message key="SD" i18n="Sudan@@SD">Sudan</bc-message>
  <bc-message key="SR" i18n="Suriname@@SR">Suriname</bc-message>
  <bc-message key="SJ" i18n="Svalbard and Jan Mayen@@SJ">Svalbard and Jan Mayen</bc-message>
  <bc-message key="SZ" i18n="Swaziland@@SZ">Swaziland</bc-message>
  <bc-message key="SE" i18n="Sweden@@SE">Sweden</bc-message>
  <bc-message key="CH" i18n="Switzerland@@CH">Switzerland</bc-message>
  <bc-message key="SY" i18n="Syrian Arab Republic@@SY">Syrian Arab Republic</bc-message>
  <bc-message key="TW" i18n="Taiwan@@TW">Taiwan</bc-message>
  <bc-message key="TJ" i18n="Tajikistan@@TJ">Tajikistan</bc-message>
  <bc-message key="TZ" i18n="Tanzania, United Republic of@@TZ">Tanzania, United Republic of</bc-message>
  <bc-message key="TH" i18n="Thailand@@TH">Thailand</bc-message>
  <bc-message key="TL" i18n="Timor-Leste@@TL">Timor-Leste</bc-message>
  <bc-message key="TG" i18n="Togo@@TG">Togo</bc-message>
  <bc-message key="TK" i18n="Tokelau@@TK">Tokelau</bc-message>
  <bc-message key="TO" i18n="Tonga@@TO">Tonga</bc-message>
  <bc-message key="TT" i18n="Trinidad and Tobago@@TT">Trinidad and Tobago</bc-message>
  <bc-message key="TN" i18n="Tunisia@@TN">Tunisia</bc-message>
  <bc-message key="TR" i18n="Turkey@@TR">Turkey</bc-message>
  <bc-message key="TM" i18n="Turkmenistan@@TM">Turkmenistan</bc-message>
  <bc-message key="TC" i18n="Turks and Caicos Islands@@TC">Turks and Caicos Islands</bc-message>
  <bc-message key="TV" i18n="Tuvalu@@TV">Tuvalu</bc-message>
  <bc-message key="UG" i18n="Uganda@@UG">Uganda</bc-message>
  <bc-message key="UA" i18n="Ukraine@@UA">Ukraine</bc-message>
  <bc-message key="AE" i18n="United Arab Emirates@@AE">United Arab Emirates</bc-message>
  <bc-message key="GB" i18n="United Kingdom@@GB">United Kingdom</bc-message>
  <bc-message key="US" i18n="United States of America@@US">United States of America</bc-message>
  <bc-message key="UM" i18n="United States Minor Outlying Islands@@UM">United States Minor Outlying Islands</bc-message>
  <bc-message key="UY" i18n="Uruguay@@UY">Uruguay</bc-message>
  <bc-message key="UZ" i18n="Uzbekistan@@UZ">Uzbekistan</bc-message>
  <bc-message key="VU" i18n="Vanuatu@@VU">Vanuatu</bc-message>
  <bc-message key="VE" i18n="Venezuela@@VE">Venezuela</bc-message>
  <bc-message key="VN" i18n="Viet Nam@@VN">Viet Nam</bc-message>
  <bc-message key="VG" i18n="Virgin Islands, British@@VG">Virgin Islands, British</bc-message>
  <bc-message key="VI" i18n="Virgin Islands, U.S.@@VI">Virgin Islands, U.S.</bc-message>
  <bc-message key="WF" i18n="Wallis and Futuna@@WF">Wallis and Futuna</bc-message>
  <bc-message key="EH" i18n="Western Sahara@@EH">Western Sahara</bc-message>
  <bc-message key="YE" i18n="Yemen@@YE">Yemen</bc-message>
  <bc-message key="ZM" i18n="Zambia@@ZM">Zambia</bc-message>
  <bc-message key="ZW" i18n="Zimbabwe@@ZW">Zimbabwe</bc-message>
  <bc-message key="AX" i18n="Åland Islands@@AX">Åland Islands</bc-message>
  <bc-message key="BQ" i18n="Bonaire, Sint Eustatius and Saba@@BQ">Bonaire, Sint Eustatius and Saba</bc-message>
  <bc-message key="CW" i18n="Curaçao@@CW">Curaçao</bc-message>
  <bc-message key="GG" i18n="Guernsey@@GG">Guernsey</bc-message>
  <bc-message key="IM" i18n="Isle of Man@@IM">Isle of Man</bc-message>
  <bc-message key="JE" i18n="Jersey@@JE">Jersey</bc-message>
  <bc-message key="ME" i18n="Montenegro@@ME">Montenegro</bc-message>
  <bc-message key="BL" i18n="Saint Barthélemy@@BL">Saint Barthélemy</bc-message>
  <bc-message key="MF" i18n="Saint Martin (French part)@@MF">Saint Martin (French part)</bc-message>
  <bc-message key="RS" i18n="Serbia@@RS">Serbia</bc-message>
  <bc-message key="SX" i18n="Sint Maarten (Dutch part)@@SX">Sint Maarten (Dutch part)</bc-message>
  <bc-message key="SS" i18n="South Sudan@@SS">South Sudan</bc-message>
</bc-catch-messages>
 