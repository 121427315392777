<bc-catch-messages [manager]="bcDxMessagesManager" (finished)="setupDevextremeLocale()">
  <bc-message key="Yes" i18n="@@yes">Yes</bc-message>
  <bc-message key="No" i18n="@@no">No</bc-message>
  <bc-message key="Cancel" i18n="@@cancel">Cancel</bc-message>
  <bc-message key="Clear" i18n="@@clear">Clear</bc-message>
  <bc-message key="Done" i18n="@@done">Done</bc-message>
  <bc-message key="Loading" i18n="@@loading">Loading...</bc-message>
  <bc-message key="Select" i18n="@@select">Select...</bc-message>
  <bc-message key="Search" i18n="@@search">Search</bc-message>
  <bc-message key="Back" i18n="@@back">Back</bc-message>
  <bc-message key="OK" i18n="@@ok">OK</bc-message>

  <bc-message key="dxCollectionWidget-noDataText" i18n="devextreme library@@dxCollectionWidget-noDataText">No data to display</bc-message>

  <bc-message key="validation-required" i18n="devextreme library@@validation-required">Required</bc-message>
  <bc-message key="validation-required-formatted" i18n="devextreme library@@validation-required-formatted">{{ '{0}' }} is required</bc-message>
  <bc-message key="validation-numeric" i18n="devextreme library@@validation-numeric">Value must be a number</bc-message>
  <bc-message key="validation-numeric-formatted" i18n="devextreme library@@validation-numeric-formatted">{{ '{0}' }} must be a number</bc-message>
  <bc-message key="validation-range" i18n="devextreme library@@validation-range">Value is out of range</bc-message>
  <bc-message key="validation-range-formatted" i18n="devextreme library@@validation-range-formatted">{{ '{0}' }} is out of range</bc-message>
  <bc-message key="validation-stringLength" i18n="devextreme library@@validation-stringLength">The length of the value is not correct</bc-message>
  <bc-message key="validation-stringLength-formatted" i18n="devextreme library@@validation-stringLength-formatted">The length of {{ '{0}' }} is not correct</bc-message>
  <bc-message key="validation-custom" i18n="devextreme library@@validation-custom">Value is invalid</bc-message>
  <bc-message key="validation-custom-formatted" i18n="devextreme library@@validation-custom-formatted">{{ '{0}' }} is invalid</bc-message>
  <bc-message key="validation-compare" i18n="devextreme library@@validation-compare">Values do not match</bc-message>
  <bc-message key="validation-compare-formatted" i18n="devextreme library@@validation-compare-formatted">{{ '{0}' }} does not match</bc-message>
  <bc-message key="validation-pattern" i18n="devextreme library@@validation-pattern">Value does not match pattern</bc-message>
  <bc-message key="validation-pattern-formatted" i18n="devextreme library@@validation-pattern-formatted">{{ '{0}' }} does not match pattern</bc-message>
  <bc-message key="validation-email" i18n="devextreme library@@validation-email">Email is invalid</bc-message>
  <bc-message key="validation-email-formatted" i18n="devextreme library@@validation-email-formatted">{{ '{0}' }} is invalid</bc-message>
  <bc-message key="validation-mask" i18n="devextreme library@@validation-mask">Value is invalid</bc-message>

  <bc-message key="dxLookup-searchPlaceholder" i18n="devextreme library@@dxLookup-searchPlaceholder">Minimum character number: {{ '{0}' }}</bc-message>

  <bc-message key="dxList-pullingDownText" i18n="devextreme library@@dxList-pullingDownText">Pull down to refresh...</bc-message>
  <bc-message key="dxList-pulledDownText" i18n="devextreme library@@dxList-pulledDownText">Release to refresh...</bc-message>
  <bc-message key="dxList-refreshingText" i18n="devextreme library@@dxList-refreshingText">Refreshing...</bc-message>
  <bc-message key="dxList-pageLoadingText" i18n="devextreme library@@dxList-pageLoadingText">Loading...</bc-message>
  <bc-message key="dxList-nextButtonText" i18n="devextreme library@@dxList-nextButtonText">More</bc-message>
  <bc-message key="dxList-selectAll" i18n="devextreme library@@dxList-selectAll">Select All</bc-message>
  <bc-message key="dxListEditDecorator-delete" i18n="devextreme library@@dxListEditDecorator-delete">Delete</bc-message>
  <bc-message key="dxListEditDecorator-more" i18n="devextreme library@@dxListEditDecorator-more">More</bc-message>

  <bc-message key="dxScrollView-pullingDownText" i18n="devextreme library@@dxScrollView-pullingDownText">Pull down to refresh...</bc-message>
  <bc-message key="dxScrollView-pulledDownText" i18n="devextreme library@@dxScrollView-pulledDownText">Release to refresh...</bc-message>
  <bc-message key="dxScrollView-refreshingText" i18n="devextreme library@@dxScrollView-refreshingText">Refreshing...</bc-message>
  <bc-message key="dxScrollView-reachBottomText" i18n="devextreme library@@dxScrollView-reachBottomText">Loading...</bc-message>

  <bc-message key="dxDateBox-simulatedDataPickerTitleTime" i18n="devextreme library@@dxDateBox-simulatedDataPickerTitleTime">Select time</bc-message>
  <bc-message key="dxDateBox-simulatedDataPickerTitleDate" i18n="devextreme library@@dxDateBox-simulatedDataPickerTitleDate">Select date</bc-message>
  <bc-message key="dxDateBox-simulatedDataPickerTitleDateTime" i18n="devextreme library@@dxDateBox-simulatedDataPickerTitleDateTime">Select date and time</bc-message>
  <bc-message key="dxDateBox-validation-datetime" i18n="devextreme library@@dxDateBox-validation-datetime">Value must be a date or time</bc-message>

  <bc-message key="dxFileUploader-selectFile" i18n="devextreme library@@dxFileUploader-selectFile">Select file</bc-message>
  <bc-message key="dxFileUploader-dropFile" i18n="devextreme library@@dxFileUploader-dropFile">or Drop file here</bc-message>
  <bc-message key="dxFileUploader-bytes" i18n="devextreme library@@dxFileUploader-bytes">bytes</bc-message>
  <bc-message key="dxFileUploader-kb" i18n="devextreme library@@dxFileUploader-kb">kb</bc-message>
  <bc-message key="dxFileUploader-Mb" i18n="devextreme library@@dxFileUploader-Mb">Mb</bc-message>
  <bc-message key="dxFileUploader-Gb" i18n="devextreme library@@dxFileUploader-Gb">Gb</bc-message>
  <bc-message key="dxFileUploader-upload" i18n="devextreme library@@dxFileUploader-upload">Upload</bc-message>
  <bc-message key="dxFileUploader-uploaded" i18n="devextreme library@@dxFileUploader-uploaded">Uploaded</bc-message>
  <bc-message key="dxFileUploader-readyToUpload" i18n="devextreme library@@dxFileUploader-readyToUpload">Ready to upload</bc-message>
  <bc-message key="dxFileUploader-uploadFailedMessage" i18n="devextreme library@@dxFileUploader-uploadFailedMessage">Upload failed</bc-message>
  <bc-message key="dxFileUploader-invalidFileExtension" i18n="devextreme library@@dxFileUploader-invalidFileExtension">File type is not allowed</bc-message>
  <bc-message key="dxFileUploader-invalidMaxFileSize" i18n="devextreme library@@dxFileUploader-invalidMaxFileSize">File is too large</bc-message>
  <bc-message key="dxFileUploader-invalidMinFileSize" i18n="devextreme library@@dxFileUploader-invalidMinFileSize">File is too small</bc-message>

  <bc-message key="dxRangeSlider-ariaFrom" i18n="devextreme library@@dxRangeSlider-ariaFrom">From</bc-message>
  <bc-message key="dxRangeSlider-ariaTill" i18n="devextreme library@@dxRangeSlider-ariaTill">Till</bc-message>
  <bc-message key="dxSwitch-switchedOnText" i18n="devextreme library@@dxSwitch-switchedOnText">ON</bc-message>
  <bc-message key="dxSwitch-switchedOffText" i18n="devextreme library@@dxSwitch-switchedOffText">OFF</bc-message>

  <bc-message key="dxForm-optionalMark" i18n="devextreme library@@dxForm-optionalMark">optional</bc-message>
  <bc-message key="dxForm-requiredMessage" i18n="devextreme library@@dxForm-requiredMessage">{{ '{0}' }} is required</bc-message>

  <bc-message key="dxNumberBox-invalidValueMessage" i18n="devextreme library@@dxNumberBox-invalidValueMessage">Value must be a number</bc-message>

  <bc-message key="dxDataGrid-columnChooserTitle" i18n="devextreme library@@dxDataGrid-columnChooserTitle">Column Chooser</bc-message>
  <bc-message key="dxDataGrid-columnChooserEmptyText" i18n="devextreme library@@dxDataGrid-columnChooserEmptyText">Drag a column here to hide it</bc-message>
  <bc-message key="dxDataGrid-groupContinuesMessage" i18n="devextreme library@@dxDataGrid-groupContinuesMessage">Continues on the next page</bc-message>
  <bc-message key="dxDataGrid-groupContinuedMessage" i18n="devextreme library@@dxDataGrid-groupContinuedMessage">Continued from the previous page</bc-message>
  <bc-message key="dxDataGrid-groupHeaderText" i18n="devextreme library@@dxDataGrid-groupHeaderText">Group by This Column</bc-message>
  <bc-message key="dxDataGrid-ungroupHeaderText" i18n="devextreme library@@dxDataGrid-ungroupHeaderText">Ungroup</bc-message>
  <bc-message key="dxDataGrid-ungroupAllText" i18n="devextreme library@@dxDataGrid-ungroupAllText">Ungroup All</bc-message>
  <bc-message key="dxDataGrid-editingEditRow" i18n="devextreme library@@dxDataGrid-editingEditRow">Edit</bc-message>
  <bc-message key="dxDataGrid-editingSaveRowChanges" i18n="devextreme library@@dxDataGrid-editingSaveRowChanges">Save</bc-message>
  <bc-message key="dxDataGrid-editingCancelRowChanges" i18n="devextreme library@@dxDataGrid-editingCancelRowChanges">Cancel</bc-message>
  <bc-message key="dxDataGrid-editingDeleteRow" i18n="devextreme library@@dxDataGrid-editingDeleteRow">Delete</bc-message>
  <bc-message key="dxDataGrid-editingUndeleteRow" i18n="devextreme library@@dxDataGrid-editingUndeleteRow">Undelete</bc-message>
  <bc-message key="dxDataGrid-editingConfirmDeleteMessage" i18n="devextreme library@@dxDataGrid-editingConfirmDeleteMessage">Are you sure you want to delete this record?</bc-message>
  <bc-message key="dxDataGrid-validationCancelChanges" i18n="devextreme library@@dxDataGrid-validationCancelChanges">Cancel changes</bc-message>
  <bc-message key="dxDataGrid-groupPanelEmptyText" i18n="devextreme library@@dxDataGrid-groupPanelEmptyText">Drag a column header here to group by that column</bc-message>
  <bc-message key="dxDataGrid-noDataText" i18n="devextreme library@@dxDataGrid-noDataText">No data</bc-message>
  <bc-message key="dxDataGrid-searchPanelPlaceholder" i18n="devextreme library@@dxDataGrid-searchPanelPlaceholder">Search...</bc-message>
  <bc-message key="dxDataGrid-filterRowShowAllText" i18n="devextreme library@@dxDataGrid-filterRowShowAllText">(All)</bc-message>
  <bc-message key="dxDataGrid-filterRowResetOperationText" i18n="devextreme library@@dxDataGrid-filterRowResetOperationText">Reset</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationEquals" i18n="devextreme library@@dxDataGrid-filterRowOperationEquals">Equals</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationNotEquals" i18n="devextreme library@@dxDataGrid-filterRowOperationNotEquals">Does not equal</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationLess" i18n="devextreme library@@dxDataGrid-filterRowOperationLess">Less than</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationLessOrEquals" i18n="devextreme library@@dxDataGrid-filterRowOperationLessOrEquals">Less than or equal to</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationGreater" i18n="devextreme library@@dxDataGrid-filterRowOperationGreater">Greater than</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationGreaterOrEquals" i18n="devextreme library@@dxDataGrid-filterRowOperationGreaterOrEquals">Greater than or equal to</bc-message>

  <bc-message key="dxDataGrid-filterRowOperationStartsWith" i18n="devextreme library@@dxDataGrid-filterRowOperationStartsWith">Starts with</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationContains" i18n="devextreme library@@dxDataGrid-filterRowOperationContains">Contains</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationNotContains" i18n="devextreme library@@dxDataGrid-filterRowOperationNotContains">Does not contain</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationEndsWith" i18n="devextreme library@@dxDataGrid-filterRowOperationEndsWith">Ends with</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationBetween" i18n="devextreme library@@dxDataGrid-filterRowOperationBetween">Between</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationBetweenStartText" i18n="devextreme library@@dxDataGrid-filterRowOperationBetweenStartText">Start</bc-message>
  <bc-message key="dxDataGrid-filterRowOperationBetweenEndText" i18n="devextreme library@@dxDataGrid-filterRowOperationBetweenEndText">End</bc-message>
  <bc-message key="dxDataGrid-applyFilterText" i18n="devextreme library@@dxDataGrid-applyFilterText">Apply filter</bc-message>
  <bc-message key="dxDataGrid-trueText" i18n="devextreme library@@dxDataGrid-trueText">true</bc-message>
  <bc-message key="dxDataGrid-falseText" i18n="devextreme library@@dxDataGrid-falseText">false</bc-message>
  <bc-message key="dxDataGrid-sortingAscendingText" i18n="devextreme library@@dxDataGrid-sortingAscendingText">Sort Ascending</bc-message>
  <bc-message key="dxDataGrid-sortingDescendingText" i18n="devextreme library@@dxDataGrid-sortingDescendingText">Sort Descending</bc-message>
  <bc-message key="dxDataGrid-sortingClearText" i18n="devextreme library@@dxDataGrid-sortingClearText">Clear Sorting</bc-message>
  <bc-message key="dxDataGrid-editingSaveAllChanges" i18n="devextreme library@@dxDataGrid-editingSaveAllChanges">Save changes</bc-message>
  <bc-message key="dxDataGrid-editingCancelAllChanges" i18n="devextreme library@@dxDataGrid-editingCancelAllChanges">Discard changes</bc-message>
  <bc-message key="dxDataGrid-editingAddRow" i18n="devextreme library@@dxDataGrid-editingAddRow">Add a row</bc-message>
  <bc-message key="dxDataGrid-summaryMin" i18n="devextreme library@@dxDataGrid-summaryMin">Min: {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summaryMinOtherColumn" i18n="devextreme library@@dxDataGrid-summaryMinOtherColumn">Min of {{ '{1}' }} is {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summaryMax" i18n="devextreme library@@dxDataGrid-summaryMax">Max: {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summaryMaxOtherColumn" i18n="devextreme library@@dxDataGrid-summaryMaxOtherColumn">Max of {{ '{1}' }} is {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summaryAvg" i18n="devextreme library@@dxDataGrid-summaryAvg">Avg: {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summaryAvgOtherColumn" i18n="devextreme library@@dxDataGrid-summaryAvgOtherColumn">Avg of {{ '{1}' }} is {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summarySum" i18n="devextreme library@@dxDataGrid-summarySum">Sum: {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summarySumOtherColumn" i18n="devextreme library@@dxDataGrid-summarySumOtherColumn">Sum of {{ '{1}' }} is {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-summaryCount" i18n="devextreme library@@dxDataGrid-summaryCount">Count: {{ '{0}' }}</bc-message>
  <bc-message key="dxDataGrid-columnFixingFix" i18n="devextreme library@@dxDataGrid-columnFixingFix">Fix</bc-message>
  <bc-message key="dxDataGrid-columnFixingUnfix" i18n="devextreme library@@dxDataGrid-columnFixingUnfix">Unfix</bc-message>
  <bc-message key="dxDataGrid-columnFixingLeftPosition" i18n="devextreme library@@dxDataGrid-columnFixingLeftPosition">To the left</bc-message>
  <bc-message key="dxDataGrid-columnFixingRightPosition" i18n="devextreme library@@dxDataGrid-columnFixingRightPosition">To the right</bc-message>
  <bc-message key="dxDataGrid-exportTo" i18n="devextreme library@@dxDataGrid-exportTo">Export</bc-message>
  <bc-message key="dxDataGrid-exportToExcel" i18n="devextreme library@@dxDataGrid-exportToExcel">Export to Excel file</bc-message>
  <bc-message key="dxDataGrid-excelFormat" i18n="devextreme library@@dxDataGrid-excelFormat">Excel file</bc-message>
  <bc-message key="dxDataGrid-selectedRows" i18n="devextreme library@@dxDataGrid-selectedRows">Selected rows</bc-message>
  <bc-message key="dxDataGrid-exportSelectedRows" i18n="devextreme library@@dxDataGrid-exportSelectedRows">Export selected rows</bc-message>
  <bc-message key="dxDataGrid-exportAll" i18n="devextreme library@@dxDataGrid-exportAll">Export all data</bc-message>
  <bc-message key="dxDataGrid-headerFilterEmptyValue" i18n="devextreme library@@dxDataGrid-headerFilterEmptyValue">(Blanks)</bc-message>
  <bc-message key="dxDataGrid-headerFilterOK" i18n="devextreme library@@dxDataGrid-headerFilterOK">OK</bc-message>
  <bc-message key="dxDataGrid-headerFilterCancel" i18n="devextreme library@@dxDataGrid-headerFilterCancel">Cancel</bc-message>
  <bc-message key="dxDataGrid-ariaColumn" i18n="devextreme library@@dxDataGrid-ariaColumn">Column</bc-message>
  <bc-message key="dxDataGrid-ariaValue" i18n="devextreme library@@dxDataGrid-ariaValue">Value</bc-message>
  <bc-message key="dxDataGrid-ariaFilterCell" i18n="devextreme library@@dxDataGrid-ariaFilterCell">Filter cell</bc-message>
  <bc-message key="dxDataGrid-ariaCollapse" i18n="devextreme library@@dxDataGrid-ariaCollapse">Collapse</bc-message>
  <bc-message key="dxDataGrid-ariaExpand" i18n="devextreme library@@dxDataGrid-ariaExpand">Expand</bc-message>
  <bc-message key="dxDataGrid-ariaDataGrid" i18n="devextreme library@@dxDataGrid-ariaDataGrid">Data grid</bc-message>
  <bc-message key="dxDataGrid-ariaSearchInGrid" i18n="devextreme library@@dxDataGrid-ariaSearchInGrid">Search in data grid</bc-message>
  <bc-message key="dxDataGrid-ariaSelectAll" i18n="devextreme library@@dxDataGrid-ariaSelectAll">Select all</bc-message>
  <bc-message key="dxDataGrid-ariaSelectRow" i18n="devextreme library@@dxDataGrid-ariaSelectRow">Select row</bc-message>
  <bc-message key="dxDataGrid-filterBuilderPopupTitle" i18n="devextreme library@@dxDataGrid-filterBuilderPopupTitle">Filter Builder</bc-message>
  <bc-message key="dxDataGrid-filterPanelCreateFilter" i18n="devextreme library@@dxDataGrid-filterPanelCreateFilter">Create Filter</bc-message>
  <bc-message key="dxDataGrid-filterPanelClearFilter" i18n="devextreme library@@dxDataGrid-filterPanelClearFilter">Clear</bc-message>
  <bc-message key="dxDataGrid-filterPanelFilterEnabledHint" i18n="devextreme library@@dxDataGrid-filterPanelFilterEnabledHint">Enable the filter</bc-message>

  <bc-message key="dxTreeList-ariaTreeList" i18n="devextreme library@@dxTreeList-ariaTreeList">Tree list</bc-message>
  <bc-message key="dxTreeList-editingAddRowToNode" i18n="devextreme library@@dxTreeList-editingAddRowToNode">Add</bc-message>

  <bc-message key="dxPager-infoText" i18n="devextreme library@@dxPager-infoText">Page {{ '{0}' }} of {{ '{1}' }} ({{ '{2}' }} items)</bc-message>
  <bc-message key="dxPager-pagesCountText" i18n="devextreme library@@dxPager-pagesCountText">of</bc-message>

  <bc-message key="dxPivotGrid-grandTotal" i18n="devextreme library@@dxPivotGrid-grandTotal">Grand Total</bc-message>
  <bc-message key="dxPivotGrid-total" i18n="devextreme library@@dxPivotGrid-total">{{ '{0}' }} Total</bc-message>
  <bc-message key="dxPivotGrid-fieldChooserTitle" i18n="devextreme library@@dxPivotGrid-fieldChooserTitle">Field Chooser</bc-message>
  <bc-message key="dxPivotGrid-showFieldChooser" i18n="devextreme library@@dxPivotGrid-showFieldChooser">Show Field Chooser</bc-message>
  <bc-message key="dxPivotGrid-expandAll" i18n="devextreme library@@dxPivotGrid-expandAll">Expand All</bc-message>
  <bc-message key="dxPivotGrid-collapseAll" i18n="devextreme library@@dxPivotGrid-collapseAll">Collapse All</bc-message>
  <bc-message key="dxPivotGrid-sortColumnBySummary" i18n="devextreme library@@dxPivotGrid-sortColumnBySummary">Sort \"{{ '{0}' }}\" by This Column</bc-message>
  <bc-message key="dxPivotGrid-sortRowBySummary" i18n="devextreme library@@dxPivotGrid-sortRowBySummary">Sort \"{{ '{0}' }}\" by This Row</bc-message>
  <bc-message key="dxPivotGrid-removeAllSorting" i18n="devextreme library@@dxPivotGrid-removeAllSorting">Remove All Sorting</bc-message>
  <bc-message key="dxPivotGrid-dataNotAvailable" i18n="devextreme library@@dxPivotGrid-dataNotAvailable">N/A</bc-message>
  <bc-message key="dxPivotGrid-rowFields" i18n="devextreme library@@dxPivotGrid-rowFields">Row Fields</bc-message>
  <bc-message key="dxPivotGrid-columnFields" i18n="devextreme library@@dxPivotGrid-columnFields">Column Fields</bc-message>
  <bc-message key="dxPivotGrid-dataFields" i18n="devextreme library@@dxPivotGrid-dataFields">Data Fields</bc-message>
  <bc-message key="dxPivotGrid-filterFields" i18n="devextreme library@@dxPivotGrid-filterFields">Filter Fields</bc-message>
  <bc-message key="dxPivotGrid-allFields" i18n="devextreme library@@dxPivotGrid-allFields">All Fields</bc-message>
  <bc-message key="dxPivotGrid-columnFieldArea" i18n="devextreme library@@dxPivotGrid-columnFieldArea">Drop Column Fields Here</bc-message>
  <bc-message key="dxPivotGrid-dataFieldArea" i18n="devextreme library@@dxPivotGrid-dataFieldArea">Drop Data Fields Here</bc-message>
  <bc-message key="dxPivotGrid-rowFieldArea" i18n="devextreme library@@dxPivotGrid-rowFieldArea">Drop Row Fields Here</bc-message>
  <bc-message key="dxPivotGrid-filterFieldArea" i18n="devextreme library@@dxPivotGrid-filterFieldArea">Drop Filter Fields Here</bc-message>

  <bc-message key="dxScheduler-editorLabelTitle" i18n="devextreme library@@dxScheduler-editorLabelTitle">Subject</bc-message>
  <bc-message key="dxScheduler-editorLabelStartDate" i18n="devextreme library@@dxScheduler-editorLabelStartDate">Start Date</bc-message>
  <bc-message key="dxScheduler-editorLabelEndDate" i18n="devextreme library@@dxScheduler-editorLabelEndDate">End Date</bc-message>
  <bc-message key="dxScheduler-editorLabelDescription" i18n="devextreme library@@dxScheduler-editorLabelDescription">Description</bc-message>
  <bc-message key="dxScheduler-editorLabelRecurrence" i18n="devextreme library@@dxScheduler-editorLabelRecurrence">Repeat</bc-message>

  <bc-message key="dxScheduler-openAppointment" i18n="devextreme library@@dxScheduler-openAppointment">Open appointment</bc-message>

  <bc-message key="dxScheduler-recurrenceNever" i18n="devextreme library@@dxScheduler-recurrenceNever">Never</bc-message>
  <bc-message key="dxScheduler-recurrenceDaily" i18n="devextreme library@@dxScheduler-recurrenceDaily">Daily</bc-message>
  <bc-message key="dxScheduler-recurrenceWeekly" i18n="devextreme library@@dxScheduler-recurrenceWeekly">Weekly</bc-message>
  <bc-message key="dxScheduler-recurrenceMonthly" i18n="devextreme library@@dxScheduler-recurrenceMonthly">Monthly</bc-message>
  <bc-message key="dxScheduler-recurrenceYearly" i18n="devextreme library@@dxScheduler-recurrenceYearly">Yearly</bc-message>

  <bc-message key="dxScheduler-recurrenceEvery" i18n="devextreme library@@dxScheduler-recurrenceEvery">Every</bc-message>
  <bc-message key="dxScheduler-recurrenceEnd" i18n="devextreme library@@dxScheduler-recurrenceEnd">End repeat</bc-message>
  <bc-message key="dxScheduler-recurrenceAfter" i18n="devextreme library@@dxScheduler-recurrenceAfter">After</bc-message>
  <bc-message key="dxScheduler-recurrenceOn" i18n="devextreme library@@dxScheduler-recurrenceOn">On</bc-message>

  <bc-message key="dxScheduler-recurrenceRepeatDaily" i18n="devextreme library@@dxScheduler-recurrenceRepeatDaily">day(s)</bc-message>
  <bc-message key="dxScheduler-recurrenceRepeatWeekly" i18n="devextreme library@@dxScheduler-recurrenceRepeatWeekly">week(s)</bc-message>
  <bc-message key="dxScheduler-recurrenceRepeatMonthly" i18n="devextreme library@@dxScheduler-recurrenceRepeatMonthly">month(s)</bc-message>
  <bc-message key="dxScheduler-recurrenceRepeatYearly" i18n="devextreme library@@dxScheduler-recurrenceRepeatYearly">year(s)</bc-message>

  <bc-message key="dxScheduler-switcherDay" i18n="devextreme library@@dxScheduler-switcherDay">Day</bc-message>
  <bc-message key="dxScheduler-switcherWeek" i18n="devextreme library@@dxScheduler-switcherWeek">Week</bc-message>
  <bc-message key="dxScheduler-switcherWorkWeek" i18n="devextreme library@@dxScheduler-switcherWorkWeek">Work Week</bc-message>
  <bc-message key="dxScheduler-switcherMonth" i18n="devextreme library@@dxScheduler-switcherMonth">Month</bc-message>

  <bc-message key="dxScheduler-switcherAgenda" i18n="devextreme library@@dxScheduler-switcherAgenda">Agenda</bc-message>

  <bc-message key="dxScheduler-switcherTimelineDay" i18n="devextreme library@@dxScheduler-switcherTimelineDay">Timeline Day</bc-message>
  <bc-message key="dxScheduler-switcherTimelineWeek" i18n="devextreme library@@dxScheduler-switcherTimelineWeek">Timeline Week</bc-message>
  <bc-message key="dxScheduler-switcherTimelineWorkWeek" i18n="devextreme library@@dxScheduler-switcherTimelineWorkWeek">Timeline Work Week</bc-message>
  <bc-message key="dxScheduler-switcherTimelineMonth" i18n="devextreme library@@dxScheduler-switcherTimelineMonth">Timeline Month</bc-message>

  <bc-message key="dxScheduler-recurrenceRepeatOnDate" i18n="devextreme library@@dxScheduler-recurrenceRepeatOnDate">on date</bc-message>
  <bc-message key="dxScheduler-recurrenceRepeatCount" i18n="devextreme library@@dxScheduler-recurrenceRepeatCount">occurrence(s)</bc-message>
  <bc-message key="dxScheduler-allDay" i18n="devextreme library@@dxScheduler-allDay">All day</bc-message>

  <bc-message key="dxScheduler-confirmRecurrenceEditMessage" i18n="devextreme library@@dxScheduler-confirmRecurrenceEditMessage">Do you want to edit only this appointment or the whole series?</bc-message>
  <bc-message key="dxScheduler-confirmRecurrenceDeleteMessage" i18n="devextreme library@@dxScheduler-confirmRecurrenceDeleteMessage">Do you want to delete only this appointment or the whole series?</bc-message>

  <bc-message key="dxScheduler-confirmRecurrenceEditSeries" i18n="devextreme library@@dxScheduler-confirmRecurrenceEditSeries">Edit series</bc-message>
  <bc-message key="dxScheduler-confirmRecurrenceDeleteSeries" i18n="devextreme library@@dxScheduler-confirmRecurrenceDeleteSeries">Delete series</bc-message>
  <bc-message key="dxScheduler-confirmRecurrenceEditOccurrence" i18n="devextreme library@@dxScheduler-confirmRecurrenceEditOccurrence">Edit appointment</bc-message>
  <bc-message key="dxScheduler-confirmRecurrenceDeleteOccurrence" i18n="devextreme library@@dxScheduler-confirmRecurrenceDeleteOccurrence">Delete appointment</bc-message>

  <bc-message key="dxScheduler-noTimezoneTitle" i18n="devextreme library@@dxScheduler-noTimezoneTitle">No timezone</bc-message>
  <bc-message key="dxScheduler-moreAppointments" i18n="devextreme library@@dxScheduler-moreAppointments">{{ '{0}' }} more</bc-message>

  <bc-message key="dxCalendar-todayButtonText" i18n="devextreme library@@dxCalendar-todayButtonText">Today</bc-message>
  <bc-message key="dxCalendar-ariaWidgetName" i18n="devextreme library@@dxCalendar-ariaWidgetName">Calendar</bc-message>

  <bc-message key="dxCalendar-ariaRed" i18n="devextreme library@@dxCalendar-ariaRed">Red</bc-message>
  <bc-message key="dxCalendar-ariaGreen" i18n="devextreme library@@dxCalendar-ariaGreen">Green</bc-message>
  <bc-message key="dxCalendar-ariaBlue" i18n="devextreme library@@dxCalendar-ariaBlue">Blue</bc-message>
  <bc-message key="dxCalendar-ariaAlpha" i18n="devextreme library@@dxCalendar-ariaAlpha">Transparency</bc-message>
  <bc-message key="dxCalendar-ariaHex" i18n="devextreme library@@dxCalendar-ariaHex">Color code</bc-message>

  <bc-message key="dxTagBox-selected" i18n="devextreme library@@dxTagBox-selected">{{ '{0}' }} selected</bc-message>
  <bc-message key="dxTagBox-allSelected" i18n="devextreme library@@dxTagBox-allSelected">All selected ({{ '{0}' }})</bc-message>
  <bc-message key="dxTagBox-moreSelected" i18n="devextreme library@@dxTagBox-moreSelected">{{ '{0}' }} more</bc-message>

  <bc-message key="vizExport-printingButtonText" i18n="devextreme library@@vizExport-printingButtonText">Print</bc-message>
  <bc-message key="vizExport-titleMenuText" i18n="devextreme library@@vizExport-titleMenuText">Exporting/Printing</bc-message>
  <bc-message key="vizExport-exportButtonText" i18n="devextreme library@@vizExport-exportButtonText">{{ '{0}' }} file</bc-message>

  <bc-message key="vizExport-exportButtonText" i18n="devextreme library@@vizExport-exportButtonText">{{ '{0}' }} file</bc-message>

  <bc-message key="dxFilterBuilder-and" i18n="devextreme library@@dxFilterBuilder-and">And</bc-message>
  <bc-message key="dxFilterBuilder-or" i18n="devextreme library@@dxFilterBuilder-or">Or</bc-message>
  <bc-message key="dxFilterBuilder-notAnd" i18n="devextreme library@@dxFilterBuilder-notAnd">Not And</bc-message>
  <bc-message key="dxFilterBuilder-notOr" i18n="devextreme library@@dxFilterBuilder-notOr">Not Or</bc-message>
  <bc-message key="dxFilterBuilder-addCondition" i18n="devextreme library@@dxFilterBuilder-addCondition">Add Condition</bc-message>
  <bc-message key="dxFilterBuilder-addGroup" i18n="devextreme library@@dxFilterBuilder-addGroup">Add Group</bc-message>
  <bc-message key="dxFilterBuilder-enterValueText" i18n="devextreme library@@dxFilterBuilder-enterValueText">{{ '<' }}enter a value{{ '>' }}</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationEquals" i18n="devextreme library@@dxFilterBuilder-filterOperationEquals">Equals</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationNotEquals" i18n="devextreme library@@dxFilterBuilder-filterOperationNotEquals">Does not equal</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationLess" i18n="devextreme library@@dxFilterBuilder-filterOperationLess">Is less than</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationLessOrEquals" i18n="devextreme library@@dxFilterBuilder-filterOperationLessOrEquals">Is less than or equal to</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationGreater" i18n="devextreme library@@dxFilterBuilder-filterOperationGreater">Is greater than</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationGreaterOrEquals" i18n="devextreme library@@dxFilterBuilder-filterOperationGreaterOrEquals">Is greater than or equal to</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationStartsWith" i18n="devextreme library@@dxFilterBuilder-filterOperationStartsWith">Starts with</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationContains" i18n="devextreme library@@dxFilterBuilder-filterOperationContains">Contains</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationNotContains" i18n="devextreme library@@dxFilterBuilder-filterOperationNotContains">Does not contain</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationEndsWith" i18n="devextreme library@@dxFilterBuilder-filterOperationEndsWith">Ends with</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationIsBlank" i18n="devextreme library@@dxFilterBuilder-filterOperationIsBlank">Is blank</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationIsNotBlank" i18n="devextreme library@@dxFilterBuilder-filterOperationIsNotBlank">Is not blank</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationBetween" i18n="devextreme library@@dxFilterBuilder-filterOperationBetween">Is between</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationAnyOf" i18n="devextreme library@@dxFilterBuilder-filterOperationAnyOf">Is any of</bc-message>
  <bc-message key="dxFilterBuilder-filterOperationNoneOf" i18n="devextreme library@@dxFilterBuilder-filterOperationNoneOf">Is none of</bc-message>

  <bc-message key="dxHtmlEditor-dialogColorCaption" i18n="devextreme library@@dxHtmlEditor-dialogColorCaption">Change Font Color</bc-message>
  <bc-message key="dxHtmlEditor-dialogBackgroundCaption" i18n="devextreme library@@dxHtmlEditor-dialogBackgroundCaption">Change Background Color</bc-message>
  <bc-message key="dxHtmlEditor-dialogLinkCaption" i18n="devextreme library@@dxHtmlEditor-dialogLinkCaption">Add Link</bc-message>
  <bc-message key="dxHtmlEditor-dialogLinkUrlField" i18n="devextreme library@@dxHtmlEditor-dialogLinkUrlField">URL</bc-message>
  <bc-message key="dxHtmlEditor-dialogLinkTextField" i18n="devextreme library@@dxHtmlEditor-dialogLinkTextField">Text</bc-message>
  <bc-message key="dxHtmlEditor-dialogLinkTargetField" i18n="devextreme library@@dxHtmlEditor-dialogLinkTargetField">Open link in new window</bc-message>
  <bc-message key="dxHtmlEditor-dialogImageCaption" i18n="devextreme library@@dxHtmlEditor-dialogImageCaption">Add Image</bc-message>
  <bc-message key="dxHtmlEditor-dialogImageUrlField" i18n="devextreme library@@dxHtmlEditor-dialogImageUrlField">URL</bc-message>
  <bc-message key="dxHtmlEditor-dialogImageAltField" i18n="devextreme library@@dxHtmlEditor-dialogImageAltField">Alternate text</bc-message>
  <bc-message key="dxHtmlEditor-dialogImageWidthField" i18n="devextreme library@@dxHtmlEditor-dialogImageWidthField">Width (px)</bc-message>
  <bc-message key="dxHtmlEditor-dialogImageHeightField" i18n="devextreme library@@dxHtmlEditor-dialogImageHeightField">Height (px)</bc-message>
  <bc-message key="dxHtmlEditor-heading" i18n="devextreme library@@dxHtmlEditor-heading">Heading</bc-message>
  <bc-message key="dxHtmlEditor-normalText" i18n="devextreme library@@dxHtmlEditor-normalText">Normal text</bc-message>
</bc-catch-messages>
