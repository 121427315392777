import { Injectable } from '@angular/core';

@Injectable()
export class BcMessagesManager {
  translations: any;

  constructor() {
    this.translations = {};
  }

  add(key: string, translation: string) {
    this.translations[key] = translation;
  }
}
